import React from "react";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const ServiceFooter = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <footer className="main__footer">
        <div className="footer-top">
          <div className="auto-container">
            <div className="top-inner">
              <div className="left__top">
                <h3>Looking For best IT business solution</h3>
              </div>
              <div className="right__top">
                <div className="btn-box">
                  <Link to="/contact" className="theme-btn theme-btn-one">
                    <i className="icon-02"></i> Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__one">
          <div className="footer-widget-section">
            <div className="auto-container">
              <div className="row clearfix">
                {/* <div className="col-lg-6 col-md-6 col-sm-12 footer-column">
                                <div className="footer-widget footer-logo-widget wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <figure className="footer-logo">
                                        <Link to="/"><img src={Logo} alt="" /></Link>
                                    </figure>
                                    <div className="text">
                                        <p>Our technologies are designed to seamlessly integrate with your existing systems, minimizing disruption and maximizing productivity.</p>
                                    </div>
                                    <div className="btn-box">
                                        <Link to="/about" className="theme-btn theme-btn-one"><i className="icon-02"></i> About Us</Link>
                                    </div>
                                </div>
                            </div> */}

                <div className="col-lg-6 col-md-6 col-sm-12 footer-column">
                  <div
                    className="footer-widget office-widget wow fadeInUp animated"
                    data-wow-delay="400ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="widget-title">
                      <h4>Official info:</h4>
                    </div>
                    <div className="widget-content">
                      <div className="text">
                        <p>
                          {" "}
                          245 YISHUN AVENUE 9 #05-161 YISHUN SUNSHINE
                          SINGAPORE (760245)
                        </p>
                      </div>
                      <div className="contact__blocks">
                        <div className="icon">
                          <i className="icon-19"></i>
                        </div>
                        <div className="contact__text">
                          <Link to="tel:+6580866050">+65 90059743</Link>
                          <br />
                        </div>
                      </div>
                      <div className="contact__blocks">
                        <div className="icon two">
                          <i className="icon-20"></i>
                        </div>
                        <div className="contact__text">
                          <Link to="mailto:admin@siw.sg">admin@siw.sg</Link>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="top__hrader__right">
                    <ul>
                      <li>
                        <Link
                          to="https://www.facebook.com/profile.php?id=61559271932769"
                          target="_blank"
                        >
                          <i className="icon-10"></i>
                        </Link>
                      </li>
                      {/* <li><Link to="#"><i className="icon-instagram"></i></Link></li> */}
                      <li>
                        <Link
                          to="https://www.linkedin.com/company/smart-ilab-works/"
                          target="_blank"
                        >
                          <i className="icon-01"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.instagram.com/smart_ilab_works/"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faInstagram} size="lg" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.youtube.com/channel/UC_PV6LzesweJvuDkYIb0AVw"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faYoutube} size="lg" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                  <div
                    className="footer-widget news-letter-widget ml_80 wow fadeInUp animated"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="widget-title">
                      <h4>Services</h4>
                    </div>
                    <div className="links">
                      <ul>
                        <li>
                          <Link to="/RPA-AI">RPA & AI</Link>
                        </li>
                        <li>
                          <Link to="/Whatsapp-estore">WhatsApp Services</Link>
                        </li>
                        <li>
                          <Link to="/Web-Development">Web Development</Link>
                        </li>
                        <li>
                          <Link to="/Data-Engineering">Data Engineering</Link>
                        </li>
                        <li>
                          <Link to="/Data-Science">Data Science</Link>
                        </li>

                        <li>
                          <Link to="/Cloud-Computing">Cloud Computing</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                  <div
                    className="footer-widget news-letter-widget ml_80 wow fadeInUp animated"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="widget-title">
                      <h4>Training</h4>
                    </div>
                    <div className="links">
                      <ul>
                        <li>
                          <Link to="/Rpa-training">RPA & AI</Link>
                        </li>
                        <li>
                          <Link to="/data-engineering-course">
                            Cloudera Data Engineering
                          </Link>
                        </li>
                        <li>
                          <Link to="/Machine-learning">
                            Cloudera Machine Learning
                          </Link>
                        </li>
                        <li>
                          <Link to="/data-analyst">Cloudera Data Analyst</Link>
                        </li>
                        <li>
                          <Link to="/cloud">Cloudera Administrator</Link>
                        </li>
                        <li>
                          <Link to="/python">Just enough python</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-6 col-sm-12 footer-column pr-0">
                                <div className="footer-widget gallery-widget wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                                    <div className="widget-title">
                                        <h4>Gallery</h4>
                                    </div>
                                    <div className="widget_content">
                                        <ul className="instagram_list clearfix">
                                            <li>
                                                <div className="inner__box">
                                                    <div className="image__box">
                                                        <Link to="/projects-details"><img src={g2} alt="" /></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="inner__box">
                                                    <div className="image__box">
                                                        <Link to="/projects-details"><img src={g3} alt="" /></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="inner__box">
                                                    <div className="image__box">
                                                        <Link to="/projects-details"><img src={g4} alt="" /></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="inner__box">
                                                    <div className="image__box">
                                                        <Link to="/projects-details"><img src={g1} alt="" /></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="inner__box">
                                                    <div className="image__box">
                                                        <Link to="/projects-details"><img src={g5} alt="" /></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="inner__box">
                                                    <div className="image__box">
                                                        <Link to="/projects-details"><img src={g6} alt="" /></Link>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="auto-container">
              <div className="bottom-inner">
                <div className="copyright">
                  <p>
                    © 2024 <Link to="/">SIW.</Link> All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ServiceFooter;
